<template>
  <div class="h-screen relative flex justify-center items-center">
    <div class="absolute top-[20%] flex justify-center items-center flex-col text-gray-800 gap-[10px]">
      <h1 class="text-[8rem] font-semibold tracking-widest text-neutral-600 leading-normal">
        404
      </h1>

      <p
        v-for="({ text }, index) in getErrorFeedback.content"
        :key="index"
        class="text-base"
      >
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script setup>
const i18n = useNuxtApp().$i18n;

const getErrorFeedback = computed(() => {
  return {
    content: [
      { text: i18n.t('home.errors.404.text_1') },
      { text: i18n.t('home.errors.404.text_2') }
    ]
  };
});
</script>
